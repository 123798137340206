import type { DefaultTheme } from 'styled-components'

import { toRem } from './utils'

const colors = {
  pink: {
    200: '#f7e3ed',
    500: '#cc518d',
  },
  cyan: {
    100: '#ecf6f7',
    500: '#4aa8b2',
  },
  red: {
    500: '#ff1e56',
  },
  yellow: {
    300: '#ffd868',
  },
  gray: {
    100: '#f5f5f5',
    200: '#dedede',
    300: '#bdbdbd',
    400: '#adadad',
    600: '#333333',
    700: '#222222',
    900: '#000000',
  },
  white: {
    900: '#ffffff',
  },
}

const spacing = [8, 16, 32, 40, 64, 128].map(toRem)

const breakpoints = {
  xxs: toRem(320),
  xs: toRem(375),
  sm: toRem(425),
  md: toRem(768),
  lg: toRem(1024),
  xl: toRem(1440),
  xxl: toRem(1680),
  '4k': toRem(2560),
}

const theme: DefaultTheme = {
  breakpoints,
  spacing,
  colors: {
    ...colors,
    primary: colors.pink[500],
    secondary: colors.cyan[500],
    error: colors.red[500],
    warning: colors.yellow[300],
  },
} as const

export default theme
