export function toRem(px: number | string): string {
  const sanitizedPixelsValue = typeof px === 'string' ? Number(px.replace('px', '')) : px

  if (Number.isNaN(sanitizedPixelsValue)) {
    throw new Error(`Conversion to rem units failed. Input: ${px}`)
  }

  const calculatedRems = sanitizedPixelsValue / 16

  return `${calculatedRems}rem`
}
