import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import type { IFixedObject } from 'gatsby-background-image'

interface ISEOMeta {
  content: string

  name?: string
  property?: string
}

interface ISEOProps {
  image?: IFixedObject
  /**
   * @default ''
   */
  title?: string
  /**
   * @default ''
   */
  description?: string
  /**
   * @default 'cs'
   */
  lang?: string
  /**
   * @default []
   */
  meta?: ISEOMeta[]
}

export default function SEO({
  image,
  title = '',
  description = '',
  lang = 'cs',
  meta = [],
}: ISEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaImage = image?.src ? `${site.siteMetadata.siteUrl}${image.src}` : null
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(', '),
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...(metaImage
          ? [
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:image:alt',
                content: title,
              },
              {
                property: 'og:image:width',
                content: image?.width,
              },
              {
                property: 'og:image:height',
                content: image?.height,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
            ]
          : [
              {
                name: 'twitter:card',
                content: 'summary',
              },
            ]),
        ...meta,
      ]}
    />
  )
}
