import { css } from 'styled-components'

import theme from '@theme'
import { toRem } from './utils'

export const baseText = css`
  font-size: ${toRem(16)};
  font-weight: 300;
`

export const heading = {
  h1: css`
    font-size: ${toRem(96)};
    color: ${theme.colors.gray[900]};
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 4px 3px rgba(0, 0, 0, 0.4), 0 8px 13px rgba(0, 0, 0, 0.1),
      0 18px 23px rgba(0, 0, 0, 0.1);
    letter-spacing: ${toRem(1.5)};
    line-height: 1.2;

    @media screen and (max-width: ${theme.breakpoints.xxl}) {
      font-size: ${toRem(80)};
    }

    @media screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: ${toRem(56)};
    }
  `,
  h2: css`
    margin-bottom: ${theme.spacing[2]};
    font-size: ${toRem(32)};
    color: ${theme.colors.gray[600]};
    font-weight: 500;
    letter-spacing: ${toRem(1.2)};
    line-height: 1.2;
  `,
  h3: css`
    margin-bottom: ${theme.spacing[1]};
    font-size: ${toRem(24)};
    color: ${theme.colors.gray[700]};
    font-weight: 500;
    letter-spacing: ${toRem(1.2)};
    line-height: 1.2;
  `,
}

export const paragraph = css`
  ${baseText}
  margin-bottom: ${theme.spacing[1]};
  line-height: 1.3;
`

export const link = css`
  ${baseText}
  font-weight: 500;
  text-decoration: none;
  color: ${theme.colors.secondary};
  transition: color 200ms ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.gray[200]};
  }
`
